<div class="flex-full-screen">
  <ngx-loading class="global-busy" [show]="busy?.show" [text]="busy?.text"></ngx-loading>

  <p-toast class="toast-global"
    key="global"
    position="top-center"
    [preventOpenDuplicates]="true">
    <ng-template #message let-message>
      <div [ngSwitch]="message.data?.type"
        style="display: contents;">
        <div *ngSwitchCase="'genericErrorMessage'"
          class="message-with-link flex-1 flex-row gap-1">
          <span class="p-toast-message-icon fas fa-sync-alt">
          </span>

          <div class="p-toast-message-text">
            <div class="p-toast-summary">{{ message.summary }}</div>
            <div class="p-toast-detail">{{ 'Something went wrong. If the problem persists, please' }} <a href="https://help.miradishare.org/hc/en-us/requests/new" target="_blank">{{ 'contact Miradi support' }}</a>.</div>
          </div>
        </div>

        <div *ngSwitchCase="'projectEditStatus'"
          class="message-with-link flex-1 flex-row gap-1">
          <span class="p-toast-message-icon fas fa-sync-alt">
          </span>

          <div class="p-toast-message-text">
            <div class="p-toast-summary">{{ message.summary }}</div>
            <a href="javascript: void(0);" (click)="refreshProject()">{{ message.detail }}</a>
          </div>
        </div>

        <div *ngSwitchDefault
          class="flex-1 flex-row gap-1">
          <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')"
            [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
            'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}">
          </span>
          <div class="p-toast-message-text">
            <div class="p-toast-summary">{{ message.summary }}</div>
            <div class="p-toast-detail">{{ message.detail }}</div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-toast>

  <div class="body">
    <miradi-maintenance-banner></miradi-maintenance-banner>
    <miradi-eula-banner [hidden]="(currentPageUrl || '').indexOf('/account-setup') >= 0"></miradi-eula-banner>
    <miradi-survey-banner></miradi-survey-banner>
    <router-outlet></router-outlet>
  </div>
</div>

<miradi-info-dialog></miradi-info-dialog>

<div id="plugin-scripts"></div>


