export const environment = {
  name: 'prod',
  production: true,

  environmentBaseUrl: '',

  appBaseHref: '/ux',
  apiBaseUrl: '/uxapi/v1',

  agGridLicenseKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-062342}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CENTRL_Office_Downtown_Portland,_Sitka_Technology_Group,_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_August_2025}____[v3]_[01]_MTc1NjU5NDgwMDAwMA==dc8362aa0a105801038432f0ae575fd4',
  appInsightsKey: 'c58d881b-0e3b-4a20-b2fa-4d76e3793a49',
  gojsLicenseKey: '73f947e5bb6231b700ca0d2b113f69ef1bb37b359e841ff55e5341f2ef5f691d2bcbed7b59db8f90d0ad4eaf1c2ec289cfd56e21c419043cb733878e40e787aee23276e61d0b42dba20026c29efb29f5af7b75fa96b474aad92a84f3b9aec6954ef8f58118cc0ee979791421557fac48a8f8c67bff',
  googleAnalyticsKey: 'G-28FKB8KPP7',
  recaptchaKey: '6Lc8dksUAAAAACr__RNtoSlEYrQ6meIUudBKuMOr',

  featureFlags: {
    newWidget: true,
    smartGuide: true,
    tocGuide: true,
  },
};
