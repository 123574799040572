import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';


@Component({
    selector: 'miradi-rtf-editor',
    templateUrl: './rtf-editor.component.html',
    styleUrls: ['./rtf-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RtfEditorComponent implements AfterViewInit, OnInit, OnDestroy {

  readonly modules = {
    clipboard: {
      matchVisual: false
    },
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: function (range, context) {
            return true;
          },
        },
      }
    }
  };

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: any;

  @Input() alwaysShowToolbar: boolean;
  @Input() set autoFocus(value: boolean) {
    this._autoFocus = value;
    if (this._autoFocus) {
      this.focusEditor();
    }
  }
  get autoFocus(): boolean {
    return this._autoFocus;
  }
  private _autoFocus: boolean;

  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  active: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.clickOutsideCheck = this.clickOutsideCheck.bind(this);
  }

  ngOnInit() {
    // set cleanUpValue on click-outside
    document.body.addEventListener('mouseup', this.clickOutsideCheck);
  }

  ngOnDestroy() {
    document.body.removeEventListener('mouseup', this.clickOutsideCheck);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.container) {
        this.clearToolbarTabIndex();
      }
    });
  }

  onEditorInit(ev: any) {
    if (!ev?.editor?.container) return;

    const container = ev.editor.container as HTMLElement;
    const parentEl = container.parentElement;
    const editor = container.querySelector('.ql-editor');
    if (!editor) return;

    editor.addEventListener('keydown', (e: any) => {
      const keyCode = e.keyCode;
      if (keyCode !== 9) return;

      this.active = false;
      this.cdr.markForCheck();
    });
    editor.addEventListener('focus', () => {
      this.active = true;
      this.cdr.markForCheck();
    });
    const toolbar = parentEl?.querySelector('.ql-toolbar');
    if (toolbar) parentEl?.insertBefore(container, toolbar);

    if (this.autoFocus) this.focusEditor();

    this.cdr.markForCheck();
  }

  // hack to prevent tab to go to toolbar buttons
  clearToolbarTabIndex(): void {
    // Get ref to the toolbar, its not available through the quill api ughh
    const query = this.container.element.nativeElement.getElementsByClassName('ql-toolbar');
    if (query.length !== 1) {
      // No toolbars found OR multiple which is not what we expect either
      return;
    }

    const toolBar = query[0];

    // apply aria labels to base buttons
    const buttons = toolBar.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      button.setAttribute('tabIndex', '-1');
    }
  }

  private clickOutsideCheck(e: any) {
    this.ngZone.run(() => {
      let container = this.container.element.nativeElement;
      if (!container.contains(e.target)) {
        if (this.active) {
          this.cleanUpValue();
          this.active = false;
          this.cdr.markForCheck();
        }
      } else {
        this.active = true;
        this.cdr.markForCheck();
      }
    });
  }

  cleanUpValue() {
    // quill wraps each line with <p> tags and we don't want that
    // here we remove the <p> and replace with <br><br>
    const previousValue = (this.value || '');
    this.value = previousValue
    .replace(/<p><br><\/p>/g, '<br>')
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '<br>')
    .replace(/&nbsp;/g, ' ');

    if (previousValue !== this.value) this.valueChange.emit(this.value);
  }

  disableKeysPropagation() {
    this.container.element.nativeElement.addEventListener('keydown', (event) => {
      const keyCode = event.keyCode;

      const KEY_ENTER = 13;
      const KEY_LEFT = 37;
      const KEY_UP = 38;
      const KEY_RIGHT = 39;
      const KEY_DOWN = 40;
      const KEY_PAGE_UP = 33;
      const KEY_PAGE_DOWN = 34;
      const KEY_PAGE_HOME = 36;
      const KEY_PAGE_END = 35;
      const preventKeyCodes = [KEY_ENTER, KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN, KEY_PAGE_UP, KEY_PAGE_DOWN, KEY_PAGE_HOME, KEY_PAGE_END];

      if (preventKeyCodes.indexOf(keyCode) >= 0) {
          // this stops the grid from receiving the event and executing keyboard navigation
          event.stopPropagation();
      }
    });
  }

  focusEditor(): void {
    setTimeout(() => {
      const query = this.container.element.nativeElement.getElementsByClassName('ql-editor');
      if (query?.length) {
        query[0].focus();
        this.cdr.markForCheck();
      }
    }, 100);
  }

}

